import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { H1 } from '@insights-ltd/design-library';
import { JobsFilter, JobsTable } from 'components/Jobs';
import { useGetJobs } from 'api/hooks';
import { GetJobsParams } from 'api';
import usePagination from 'components/hooks/usePagination';
import { useNavigate, useLocation } from 'react-router-dom';
import { IJob } from 'types/job';
import { IDialects } from 'variables';
import { styled } from '@mui/material';
import {
  FiltersType,
  useFilterContext,
} from 'components/FilterProvider/FilterProvider';
import BulkJobsStatusModal from './BulkJobStatusModal';
import sortJobs from './sortJobs';

const DEFAULT_FILTERS: GetJobsParams = {
  dialect: '',
  statuses: [
    'DRAFT',
    'AWAITING_QUOTE',
    'WITH_TRANSLATOR',
    'IN_REVIEW',
    'PENDING_PUBLISH',
  ],
  startDate: '',
  endDate: '',
  owner: '',
  translator: '',
  requester: '',
};

type BulkJobResult = {
  dialect: keyof IDialects;
  success: boolean;
  jobId: string;
};

const useHistoryStateError = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [bulkJobsStatus, setBulkJobsStatus] = useState<
    BulkJobResult[] | undefined
  >(state?.bulkJobsStatus);
  const reset = () => {
    navigate('/jobs', { replace: true });
    setBulkJobsStatus(undefined);
  };
  return {
    reset,
    bulkJobsStatus,
  };
};

const StyledH2 = styled(H1)(({ theme }) => ({
  marginTop: `${theme.spacing(2)} !important`,
}));

const StyledH4 = styled(H1)(({ theme }) => ({
  marginTop: `${theme.spacing(3)} !important`,
}));

const convertFilter = ({
  deadline: { startDate, endDate },
  status,
  ...rest
}: FiltersType) => {
  const statusList = Object.entries(status).reduce(
    (acc, [key, value]) => (value ? [...acc, key] : [...acc]),
    [] as string[],
  );
  return {
    ...rest,
    startDate,
    endDate,
    statuses: statusList,
  };
};

const Jobs = () => {
  const { bulkJobsStatus, reset } = useHistoryStateError();
  const { setFilterState, sessionData } = useFilterContext();
  const [filters, setFilters] = useState(
    sessionData ? convertFilter(sessionData) : DEFAULT_FILTERS,
  );
  const { status: getJobsStatus, data } = useGetJobs(filters);
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IJob>('id');
  const handleSort = (property: keyof IJob) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const applyFilters = (filter: FiltersType) => {
    setFilterState(filter);
    setFilters(convertFilter(filter));
  };

  const sortedJobs = React.useMemo(
    () => sortJobs(data || [], order, orderBy),
    [data, order, orderBy],
  );

  const {
    pageNumber,
    pageCount,
    pagedItems,
    handlePageChange,
    getPaginationAriaLabel,
  } = usePagination(sortedJobs, 50);

  useEffect(() => {
    if (sessionData?.lastJobOpened && sortedJobs.length > 0) {
      const index = sortedJobs.findIndex(
        ({ id }) => id === sessionData?.lastJobOpened,
      );

      const page = Math.ceil((index + 1) / 50);
      handlePageChange(page < 1 ? 1 : page);
    }
    // we have added this ignore rule due to the way usePagination renders its return payload
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData?.lastJobOpened, sortedJobs]);

  return (
    <Container
      maxWidth="lg"
      sx={(theme) => ({
        '> * + *': { marginTop: theme.spacing(3) },
      })}
    >
      <StyledH2 variant="h2">Welcome</StyledH2>
      <StyledH4 variant="h4" color="textSecondary">
        Search current jobs or start a new one.
      </StyledH4>
      <JobsFilter
        applyFilters={applyFilters}
        onClear={() => setFilters(DEFAULT_FILTERS)}
      />
      <JobsTable
        status={getJobsStatus}
        data={pagedItems}
        totalJobs={data?.length || 0}
        handleSort={handleSort}
        order={order}
        orderBy={orderBy}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {pageCount > 1 && (
          <Pagination
            count={pageCount}
            getItemAriaLabel={getPaginationAriaLabel}
            hidePrevButton
            onChange={(_, page) => handlePageChange(page)}
            page={pageNumber}
          />
        )}
      </Box>
      <BulkJobsStatusModal
        open={Boolean(bulkJobsStatus)}
        onClose={reset}
        results={bulkJobsStatus || []}
      />
    </Container>
  );
};

export default Jobs;
