import React from 'react';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Grid from '@mui/material/Grid';
import { H3, H4, Text } from '@insights-ltd/design-library';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { IJob } from 'types/job';
import {
  TRANSLATION_JOB_DIALECTS as DIALECTS,
  getAvailableStatusTransitions,
  EnumJobStatus,
  JobStatus,
} from 'variables';
import { dateFromISOToLocal } from 'utils/utils';
import { JobButton } from 'components/Jobs';
import { getExportUrl } from 'api';
import { JobsStatus } from 'components/Jobs/Table/JobsStatus';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import {
  JobDetailField,
  JobDetailDescription,
  JobFieldLabel,
} from './JobFields';

type Props = {
  job: IJob;
  enableExport?: boolean;
  onImport: (file: File) => void;
  publishJob: () => void;
  openDialog: () => void;
};

const capitalise = (str: string | undefined): string => {
  if (!str) return '';

  const words = str.split('_');
  return words
    .map((s) => `${s[0].toUpperCase()}${s.substring(1).toLowerCase()}`)
    .join(' ');
};

const disabledStatuses: JobStatus[] = [
  EnumJobStatus.CANCELLED,
  EnumJobStatus.PUBLISHED,
];

const JobDetails = ({
  job,
  enableExport = false,
  onImport,
  publishJob,
  openDialog,
}: Props) => {
  const navigate = useNavigate();

  const deadline = job.deadlineDate
    ? dateFromISOToLocal(job.deadlineDate)
    : undefined;

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    onImport(e.target.files[0]);
  };

  const onClickEdit = () => navigate(`/jobs/${job.id}/edit`);

  const exportVariant = (
    [EnumJobStatus.IN_REVIEW, EnumJobStatus.PENDING_PUBLISH] as JobStatus[]
  ).includes(job.status)
    ? 'outlined'
    : 'contained';

  const disabledStatus = disabledStatuses.includes(job.status);
  const ableToChangeStatus =
    getAvailableStatusTransitions(job.status).length > 0;
  return (
    <Grid container spacing={2}>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        xs={12}
        sx={{
          minHeight: '64px',
        }}
      >
        <H3>Details</H3>
        {!disabledStatus && (
          <JobButton
            sx={{
              whiteSpace: 'nowrap',
            }}
            onClick={onClickEdit}
            variant="outlined"
            color="primary"
          >
            Edit job details
          </JobButton>
        )}
      </Grid>
      <Grid item xs={12}>
        <Card
          sx={(theme) => ({
            padding: theme.spacing(spacingSizeMap.XS),
            border: '0',
          })}
          elevation={0}
        >
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <H4>{DIALECTS[job.dialect]}</H4>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <JobsStatus jobStatus={job.status} />
                  </Grid>
                  <Grid item>
                    {ableToChangeStatus && (
                      <JobButton
                        onClick={openDialog}
                        aria-label="Change job status"
                      >
                        Change
                      </JobButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              sx={(theme) => ({
                marginTop: theme.spacing(spacingSizeMap.S),
              })}
            >
              <JobDetailField>
                <JobFieldLabel text="Name" />
                <JobDetailDescription>
                  <Text>{job.title}</Text>
                </JobDetailDescription>
              </JobDetailField>

              <JobDetailField>
                <JobFieldLabel text="Deadline" />
                <JobDetailDescription>
                  <Text variant="body-large">{deadline}</Text>
                </JobDetailDescription>
              </JobDetailField>

              <JobDetailField>
                <JobFieldLabel text="Owner" />
                <JobDetailDescription>
                  <Text>{job.owner}</Text>
                </JobDetailDescription>
              </JobDetailField>

              <JobDetailField>
                <JobFieldLabel text="Requester" />
                <JobDetailDescription>
                  <Text>{job.requester}</Text>
                </JobDetailDescription>
              </JobDetailField>

              <JobDetailField>
                <JobFieldLabel text="Translator" />
                <JobDetailDescription>
                  <Text>{capitalise(job.translator)}</Text>
                </JobDetailDescription>
              </JobDetailField>

              <Grid
                container
                item
                spacing={1}
                sx={(theme) => ({
                  marginTop: theme.spacing(spacingSizeMap.XS),
                })}
              >
                <Grid item xs={12}>
                  <JobFieldLabel text="Description" />
                </Grid>
                <Grid item xs={12}>
                  <Text>{job.description}</Text>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions>
            {enableExport && (
              <JobButton
                variant={exportVariant}
                color="primary"
                href={getExportUrl(job.id)}
                target="_blank"
                download
                fullWidth
              >
                Export
              </JobButton>
            )}
            {job.status === EnumJobStatus.IN_REVIEW && (
              <JobButton
                variant="contained"
                color="primary"
                component="label"
                fullWidth
              >
                Import
                <input
                  accept=".csv"
                  hidden
                  type="file"
                  aria-label="Import"
                  onChange={onFileChange}
                />
              </JobButton>
            )}
            {job.status === EnumJobStatus.PENDING_PUBLISH && (
              <JobButton
                variant="contained"
                color="primary"
                onClick={publishJob}
                fullWidth
              >
                Publish
              </JobButton>
            )}
          </CardActions>
        </Card>
      </Grid>
      <Grid item>
        <Link component={RouterLink} to="/jobs">
          <Grid container alignItems="center">
            <NavigateBeforeIcon /> Back to jobs
          </Grid>
        </Link>
      </Grid>
    </Grid>
  );
};

export default JobDetails;
