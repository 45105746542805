import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { H1, Text } from '@insights-ltd/design-library';
import BulkJobForm from 'components/BulkJobForm';
import Navigation from 'components/Navigation';
import Search from 'components/Strings/Search';
import { CreateBulkJobType } from 'types/job';
import { useBulkCreateJob, BulkCreateJobError } from 'api/hooks';
import { IDialects } from 'variables';

type BulkJobResult = {
  dialect: keyof IDialects;
  success?: boolean;
  jobId?: string;
};
const AddStrings = ({ job }: { job: CreateBulkJobType }) => {
  const { mutate: bulkCreateJob, status, reset } = useBulkCreateJob();
  const navigate = useNavigate();

  const onSaveSelection = (strings: string[]) =>
    bulkCreateJob(
      { strings, job },
      {
        onSuccess: () => navigate('/jobs'),
        onError: (error) => {
          if (error instanceof BulkCreateJobError) {
            const bulkCreateJobStatusValues = Object.entries(
              error.bulkCreateJobStatus,
            );

            const bulkJobsStatus = bulkCreateJobStatusValues.map<BulkJobResult>(
              ([dialect, details]) => {
                return {
                  dialect: dialect as keyof IDialects,
                  success: details.stringAdded,
                  jobId: details.jobId,
                };
              },
            );

            navigate('/jobs', {
              state: {
                bulkJobsStatus,
              },
            });
          }
        },
      },
    );

  return (
    <>
      <Search
        dialect="en-GB"
        onSaveSelection={onSaveSelection}
        translations={[]}
        saveSelectionDisabled={status === 'pending'}
      />
      <Snackbar
        open={status === 'error'}
        autoHideDuration={6000}
        onClose={reset}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert elevation={6} variant="filled" onClose={reset} severity="error">
          Failed to create jobs, please try again later.
        </Alert>
      </Snackbar>
    </>
  );
};

const BulkJobDetails = ({
  onSubmit,
  initialState,
}: {
  onSubmit: (jobs: CreateBulkJobType) => void;
  initialState?: CreateBulkJobType;
}) => {
  const navigate = useNavigate();

  const onCancel = () => navigate('/jobs');

  return (
    <BulkJobForm onSubmit={onSubmit} initialState={initialState}>
      <Grid
        container
        justifyContent="space-between"
        sx={(theme) => ({ marginTop: theme.spacing(3) })}
      >
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Add strings
        </Button>
      </Grid>
    </BulkJobForm>
  );
};

const CreateBulkJob = () => {
  const [bulkJob, setBulkJob] = useState<CreateBulkJobType | undefined>(
    undefined,
  );
  const navigate = useNavigate();
  const advanceStage = (newBulkJob: CreateBulkJobType) => {
    setBulkJob(newBulkJob);
    navigate('add-strings');
  };

  return (
    <Container
      maxWidth="lg"
      sx={(theme) => ({
        '> * + *': { marginTop: theme.spacing(3) },
      })}
    >
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navigation
                breadcrumbs={[
                  { route: '/jobs', children: 'jobs' },
                  { route: '', children: 'New Bulk Job' },
                ]}
              />
              <Grid container>
                <Grid item xs={12} md={8}>
                  <H1 variant="h2">New Bulk Job</H1>
                  <Text color="textSecondary">
                    Create a new job to add strings
                  </Text>
                  <Box sx={(theme) => ({ mb: theme.spacing(4) })} />
                  <Card elevation={0}>
                    <CardContent>
                      <BulkJobDetails
                        onSubmit={advanceStage}
                        initialState={bulkJob}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          }
        />
        <Route
          path="add-strings"
          element={
            <>
              <Navigation
                breadcrumbs={[
                  { route: '/jobs', children: 'jobs' },
                  { route: '/jobs/createBulkJob', children: 'New Bulk Job' },
                  { route: '', children: 'Add Strings' },
                ]}
              />
              <AddStrings job={bulkJob!} />
            </>
          }
        />
      </Routes>
    </Container>
  );
};

export default CreateBulkJob;
