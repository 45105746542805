import * as React from 'react';
import { Grid, Theme } from '@mui/material';
import EntriesCounter from 'components/EntriesCounter/EntriesCounter';
import { useNavigate } from 'react-router-dom';
import { JobButton } from 'components/Jobs/Buttons/JobButton';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

interface IPropsType {
  editable: boolean;
  editStringsUri: string;
  totalStrings: number;
  onClickRemove: () => void;
}

const StringControl = ({
  editable,
  editStringsUri,
  totalStrings,
  onClickRemove,
}: IPropsType) => {
  const navigate = useNavigate();
  const onClickAdd = () => navigate(editStringsUri);

  return (
    <Grid
      container
      alignItems="center"
      sx={(theme: Theme) => ({
        paddingRight: theme.spacing(spacingSizeMap.XS),
        minHeight: '64px',
      })}
      spacing={2}
    >
      <Grid item>
        <EntriesCounter text="Strings in job" count={totalStrings} />
      </Grid>
      <Grid
        item
        sx={{
          marginLeft: 'auto',
        }}
      >
        {totalStrings > 0 && editable && (
          <JobButton
            variant="outlined"
            onClick={onClickRemove}
            sx={(theme) => ({
              color: theme.palette.error.main,
              borderColor: theme.palette.error.main,
              border: '1px solid',
              backgroundColor: 'transparent',
              '&:hover': {
                borderColor: theme.palette.error.main,
                backgroundColor: `${theme.palette.error.main}16`,
              },
            })}
          >
            Remove strings
          </JobButton>
        )}
      </Grid>
      <Grid item>
        {editable && (
          <JobButton variant="contained" color="primary" onClick={onClickAdd}>
            Add strings
          </JobButton>
        )}
      </Grid>
    </Grid>
  );
};

export default StringControl;
