import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Text } from '@insights-ltd/design-library';
import { styled } from '@mui/material';

type VARIANT = 'save' | 'remove';

type PropsType = {
  stringCount: number;
  onProceed: () => void;
  onCancel: () => void;
  variant?: VARIANT;
  disabled?: boolean;
};

const variantData: {
  [key in VARIANT]: {
    buttonProps: { color: any };
    cancelText: string;
    proceedText: string;
  };
} = {
  save: {
    buttonProps: { color: 'primary' },
    cancelText: 'Clear',
    proceedText: 'Save Selection',
  },
  remove: {
    buttonProps: { color: 'error' },
    cancelText: 'Cancel',
    proceedText: 'Remove Selection',
  },
};

const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.blue.light,
}));

const BottomBar = ({
  stringCount,
  onProceed,
  onCancel,
  variant = 'save',
  disabled = false,
}: PropsType) => {
  const variantProps = variantData[variant];
  return (
    <AppBar
      position="fixed"
      sx={(theme) => ({
        top: 'auto',
        bottom: '0',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
      })}
    >
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '85%',
        }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <StyledText variant="body">
              {stringCount} strings selected.
            </StyledText>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={onCancel}
              aria-label="Bottom bar cancel"
            >
              {variantProps.cancelText}
            </Button>
            <Button
              sx={(theme) => ({ marginLeft: theme.spacing(2) })}
              onClick={onProceed}
              variant="contained"
              disabled={disabled}
              {...variantProps.buttonProps}
            >
              {variantProps.proceedText}
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default BottomBar;
