import { setupWorker } from 'msw/browser';
import { ok } from '@insights-ltd/design-library/src/utils/mocking';
import { authentication, jobs, jobTranslations } from './handlers';
import { generateMockJobs, generateMockTranslations } from './mock-data';

// This configures a Service Worker with the given request handlers.

const user = [authentication.getUser(ok({ username: 'Homer' }))];
const mockJobs = generateMockJobs(400);
const jobsHandlers = [
  jobs.all(ok(mockJobs)),
  jobs.get('job_1')(ok(mockJobs[0])),
  jobs.get('job_292')(ok(mockJobs[291])),
  jobTranslations.get('job_1')(ok(generateMockTranslations(2))),
  jobTranslations.get('job_292')(ok(generateMockTranslations(2))),
];

const handlers = [...user, ...jobsHandlers];

export const worker = setupWorker(...handlers);
