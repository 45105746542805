import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Grid,
  SelectChangeEvent,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { H3, H4 } from '@insights-ltd/design-library';
import {
  DialectSelect,
  OwnersSelect,
  TranslatorsSelect,
} from 'components/Selects';
import { languages } from 'variables';
import {
  DEFAULT_FILTERS,
  EMPTY_FILTERS,
  FiltersType,
  useFilterContext,
} from 'components/FilterProvider/FilterProvider';
import StatusFilter from './StatusFilter';
import DeadlineFilter from './DeadlineFilter';

type Props = {
  applyFilters: (filters: FiltersType) => void;
  onClear: () => void;
};

const StyledH4 = styled(H4)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

const JobsFilter = ({ applyFilters, onClear }: Props) => {
  const { filterState, resetState, clearState } = useFilterContext();

  const [filters, setFilters] = useState(filterState);

  const onFilterChange = <T,>(
    event:
      | SelectChangeEvent<T>
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFilters((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onStatusChange = (event: ChangeEvent<HTMLInputElement>) =>
    setFilters((prevState) => ({
      ...prevState,
      status: {
        ...prevState.status,
        [event.target.name]: event.target.checked,
      },
    }));

  const onDeadlineChange = (
    date: string | undefined,
    type: 'startDate' | 'endDate',
  ) => {
    return setFilters((prevState) => ({
      ...prevState,
      deadline: { ...prevState.deadline, [type]: date },
    }));
  };

  const clearFilters = () => {
    setFilters(EMPTY_FILTERS);
    clearState();
    onClear();
  };

  const resetFilter = () => {
    setFilters(DEFAULT_FILTERS);
    resetState();
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="filter-content"
          id="filter-header"
        >
          <H3>Filters</H3>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <StyledH4>Target Language</StyledH4>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="language-label">Select a language</InputLabel>
                <DialectSelect
                  onChange={onFilterChange}
                  value={filters.dialect}
                  id="langauge"
                  name="dialect"
                  label="Select a language"
                  labelId="language-label"
                  options={languages}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <StyledH4>Status</StyledH4>
              <StatusFilter
                selectedStatus={filters.status}
                onStatusChange={onStatusChange}
              />
            </Grid>
            <Grid item xs={3}>
              <StyledH4>Deadline</StyledH4>
              <FormControl fullWidth variant="outlined">
                <DeadlineFilter
                  deadline={filters.deadline}
                  onDeadlineChange={onDeadlineChange}
                />
              </FormControl>
            </Grid>
            <Grid container item direction="column" xs={3} spacing={3}>
              <Grid item>
                <StyledH4>Owner</StyledH4>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Select owner</InputLabel>
                  <OwnersSelect
                    value={filters.owner}
                    onChange={onFilterChange}
                    label="Select owner"
                    name="owner"
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <StyledH4>Translator</StyledH4>
                <FormControl fullWidth variant="outlined">
                  <InputLabel> Select translator</InputLabel>
                  <TranslatorsSelect
                    label="Select translator"
                    value={filters.translator}
                    onChange={onFilterChange}
                    name="translator"
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <StyledH4>Requester</StyledH4>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Enter Requester</InputLabel>
                  <OutlinedInput
                    label="Enter Requester"
                    value={filters.requester}
                    onChange={onFilterChange}
                    type="search"
                    inputProps={{ 'aria-label': 'Enter Requester' }}
                    name="requester"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionActions
          sx={(theme) => ({
            padding: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(
              2,
            )}`,
          })}
        >
          <Button
            variant="text"
            onClick={resetFilter}
            startIcon={<RefreshIcon />}
          >
            Reset filters
          </Button>
          <Button
            variant="outlined"
            onClick={clearFilters}
            startIcon={<CloseIcon />}
          >
            Clear filters
          </Button>
          <Button
            onClick={() => applyFilters(filters)}
            variant="contained"
            color="primary"
          >
            Search jobs
          </Button>
        </AccordionActions>
      </Accordion>
    </div>
  );
};

export default JobsFilter;
