import { createBetaFlags } from '@insights-ltd/design-library';

export const BETA_DEFAULT = false;

const PRODUCTION_HOSTNAME = 'localisation.insights.com';

export const getBetaAvailable = () => {
  return window.location.hostname !== PRODUCTION_HOSTNAME;
};

/*
When adding a new beta feature, add its name here.
When releasing a beta feature to production, remove it from here and TypeScript
will fail the build until every usage of it is removed.
 */
export type FEATURE = never;

export const { useBetaEnabled, BetaFeature } = createBetaFlags<FEATURE>();
