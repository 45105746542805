import React from 'react';
import { Container, Grid, styled } from '@mui/material';
import { H3 } from '@insights-ltd/design-library';
import { IJob } from 'types/job';
import Navigation from 'components/Navigation';

type PropsType = {
  job: IJob;
};

const StyledH3 = styled(H3)(({ theme }) => ({
  color: theme.palette.info.light,
  paddingLeft: theme.spacing(1),
}));

const SearchHeader = ({ job }: PropsType) => {
  return (
    <Container disableGutters>
      <Grid container spacing={3}>
        <Grid container item>
          <Navigation
            breadcrumbs={[
              { route: '/jobs', children: 'jobs' },
              {
                route: `/jobs/${job.id}`,
                children: job.id,
              },
              { route: '', children: `String Search` },
            ]}
          />
        </Grid>

        <Grid container item>
          <H3>{job.title}</H3>
          <StyledH3>{job.id}</StyledH3>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SearchHeader;
