import * as React from 'react';
import { Grid, Theme } from '@mui/material';
import { H3 } from '@insights-ltd/design-library/src';
import { JobButton } from 'components/Jobs/Buttons/JobButton';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

type Props = {
  allStringsSelected: boolean;
  setSelectedStrings: (key: string[]) => void;
  stopRemovingStrings: () => void;
  onSelectAllStrings: () => void;
};

const RemovingStringsControl = ({
  allStringsSelected,
  setSelectedStrings,
  stopRemovingStrings,
  onSelectAllStrings,
}: Props) => {
  return (
    <Grid
      container
      alignItems="center"
      sx={(theme: Theme) => ({
        paddingRight: theme.spacing(spacingSizeMap.XS),
      })}
      spacing={1}
    >
      <Grid item>
        <H3>Remove strings from job</H3>
      </Grid>
      <Grid
        item
        sx={{
          marginLeft: 'auto',
        }}
      >
        <JobButton
          variant="outlined"
          color="primary"
          onClick={
            allStringsSelected
              ? () => setSelectedStrings([])
              : onSelectAllStrings
          }
        >
          {allStringsSelected ? 'Deselect all strings' : 'Select all strings'}
        </JobButton>
      </Grid>
      <Grid item>
        <JobButton
          variant="outlined"
          color="primary"
          onClick={stopRemovingStrings}
        >
          Cancel
        </JobButton>
      </Grid>
    </Grid>
  );
};

export default RemovingStringsControl;
