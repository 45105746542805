import React from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Text, RadioGroupControl } from '@insights-ltd/design-library';
import { TranslationKeys } from 'types/api';
import { styled } from '@mui/material';
import TreeView from './TreeView';

type PropsType = {
  translationCategoryKeys: TranslationKeys;
  onSubmit: (newSearchText: string, newFilters: string[]) => void;
};

const StyledForm = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const SearchPanel = ({ translationCategoryKeys, onSubmit }: PropsType) => {
  const [searchText, setSearchText] = React.useState('');
  const [filters, setFilters] = React.useState<string[]>([]);
  const [treeFilter, setTreeFilter] = React.useState<string>(
    Object.keys(translationCategoryKeys || {})[0] || '',
  );

  const keysObject =
    treeFilter === ''
      ? translationCategoryKeys
      : (translationCategoryKeys[treeFilter] as TranslationKeys);

  const filterOptions = Object.keys(translationCategoryKeys).map((item) => ({
    value: item,
    label: item,
  }));
  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    onSubmit(searchText, filters);
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Paper sx={(theme) => ({ marginTop: theme.spacing(1) })} elevation={0}>
        <Box
          marginTop={1}
          marginBottom={1}
          sx={(theme) => ({
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
          })}
        >
          <Text variant="body-bold">Search strings</Text>
        </Box>
        <Box pl={4} pr={4}>
          <Grid
            container
            sx={(theme) => ({
              height: theme.spacing(55),
              border: `1px solid ${theme.palette.grey[500]}`,
              borderRadius: '4px',
            })}
          >
            <Grid item xs={4}>
              <Box p={2}>
                <Text variant="body-bold" color="textSecondary">
                  Select a category
                </Text>
                <RadioGroupControl
                  options={filterOptions}
                  name="roles"
                  title="Select a cateogry"
                  value={treeFilter}
                  onChange={setTreeFilter}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              sx={(theme) => ({
                height: theme.spacing(54.9),
                padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
                overflow: 'auto',
                backgroundColor: theme.palette.background.default,
                borderLeft: `1px solid ${theme.palette.grey[500]}`,
                borderBottom: `1px solid ${theme.palette.grey[500]}`,
              })}
            >
              <Text variant="body-bold" color="textSecondary">
                Filter selected category
              </Text>
              <TreeView
                keysObject={keysObject}
                path={treeFilter}
                handleSelected={setFilters}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          alignItems="center"
          spacing={5}
          sx={(theme) => ({ padding: theme.spacing(3) })}
        >
          <Grid item>
            <Container
              disableGutters
              sx={(theme) => ({
                display: 'flex',
                width: '300px',
                height: '50px',
                border: `1px solid ${theme.palette.grey[600]}`,
                borderRadius: theme.spacing(0.4),
              })}
            >
              <IconButton
                type="submit"
                sx={(theme) => ({ padding: theme.spacing(1) })}
                size="large"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                placeholder="Enter term..."
                inputProps={{ 'aria-label': 'Enter Search Term' }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                type="search"
              />
            </Container>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              aria-label="Search Panel Search Button"
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </StyledForm>
  );
};

export default SearchPanel;
