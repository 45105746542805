import { styled } from '@mui/material';
import * as React from 'react';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { IJob } from 'types/job';
import { dateFromISOToLocal } from 'utils/utils';
import { Text } from '@insights-ltd/design-library';
import { useFilterContext } from 'components/FilterProvider/FilterProvider';
import { useEffect, useRef } from 'react';
import EmptyTable from './EmptyTable';
import { JobsStatus } from './JobsStatus';

interface IPropsType {
  jobs: IJob[];
  navigateToJob: (job: IJob) => void;
}

const TitleCell = styled(Typography)({
  width: '9rem',
  textOverflow: 'ellipsis',
});

export const TableItemRow = (
  props: IJob & { lastJobOpened?: string; navigateToJob: (job: IJob) => void },
) => {
  const {
    id,
    deadlineDate,
    dialect,
    title,
    status,
    owner,
    translator,
    requester,
    lastJobOpened,
    navigateToJob,
  } = props;

  const labelId = `job ${id}`;
  const formattedDeadline = deadlineDate
    ? dateFromISOToLocal(deadlineDate)
    : 'None';

  const rawRef = useRef<HTMLTableRowElement | null>(null);

  const isInFocus = id === lastJobOpened;
  const ref = isInFocus ? rawRef : null;

  useEffect(() => {
    if (ref?.current && ref.current.scrollIntoView) {
      ref.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  }, [ref]);

  return (
    <TableRow
      data-testid={isInFocus ? `${id}-active-item` : `${id}-item`}
      ref={ref}
      hover
      onClick={() => navigateToJob(props)}
      tabIndex={-1}
      key={id}
      sx={(theme) => ({
        cursor: 'pointer',
        border:
          id === lastJobOpened
            ? `2px solid ${theme.palette.primary.dark}`
            : 'none',
      })}
    >
      <TableCell id={labelId}>
        <Text variant="body-bold">{id}</Text>
      </TableCell>
      <TableCell align="left">
        <Typography>{dialect}</Typography>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={title}>
          <TitleCell noWrap>{title}</TitleCell>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <JobsStatus jobStatus={status} />
      </TableCell>
      <TableCell align="left">
        <Typography>{formattedDeadline}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography>{owner}</Typography>
      </TableCell>
      <TableCell align="left">
        <Tooltip title={requester || ''}>
          <TitleCell noWrap>{requester}</TitleCell>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Typography>{translator}</Typography>
      </TableCell>
    </TableRow>
  );
};

const JobsTableBody = (props: IPropsType) => {
  const { jobs, navigateToJob } = props;
  const { sessionData: { lastJobOpened } = { lastJobOpened: '' } } =
    useFilterContext();

  if (jobs.length === 0) return <EmptyTable />;

  return (
    <TableBody>
      {jobs.map((job) => {
        const { id } = job;

        return (
          <TableItemRow
            key={`${id}-item`}
            {...job}
            lastJobOpened={lastJobOpened}
            navigateToJob={navigateToJob}
          />
        );
      })}
    </TableBody>
  );
};

export default JobsTableBody;
