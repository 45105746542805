import Grid from '@mui/material/Grid';
import React from 'react';
import { Text } from '@insights-ltd/design-library';
import { styled } from '@mui/material';

export const JobDetailField = ({ children }: { children: React.ReactNode }) => (
  <Grid container item spacing={2} alignItems="flex-end" sx={{ padding: '0' }}>
    {children}
  </Grid>
);

const StyledText = styled(Text)({ textTransform: 'uppercase' });

export const JobFieldLabel = ({ text }: { text: string }) => (
  <Grid item sx={{ width: '130px' }}>
    <StyledText variant="body-bold" color="textSecondary">
      {text}
    </StyledText>
  </Grid>
);

export const JobDetailDescription = ({
  children,
}: {
  children: React.ReactNode;
}) => <Grid item>{children}</Grid>;
