import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { P, Span, Tag } from '@insights-ltd/design-library';
import { IJobString, JobTranslation } from 'types/job';
import useMiddleTruncateText from 'components/hooks/useMiddleTruncateText';
import {
  ENGLISH_DIALECT,
  IDialects,
  GRAMMAR_CONTEXTS,
  GrammarContext,
} from 'variables';
import { toggleSelected } from 'components/Jobs/Strings/helperFunctions';
import EmptyList from 'components/Strings/EmptyList';
import useShowMoreText, {
  combineExpandedStatuses,
} from 'components/hooks/useShowMoreText';
import { styled } from '@mui/material';
import getTheme from '@insights-ltd/design-library/src/themes';

const localTheme = getTheme();

const StyledP = styled(P)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const StyledTag = styled(Tag)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

const StyledSpan = styled(Span)(({ theme }) => ({
  padding: theme.spacing(1),
  fontSize: '0.875rem',
  backgroundColor: theme.palette.primary.light,
  borderRadius: '4px',
}));

const StyledGrammarContext = styled(Span)(({ theme }) => ({
  padding: theme.spacing(1),
  fontWeight: localTheme.typography.boldWeight,
  fontSize: '0.875rem',
  backgroundColor: theme.palette.primary.light,
  borderRadius: '4px',
  border: `1px solid ${theme.palette.info.dark}`,
}));

const ShowHideButton = ({
  onClick,
  children,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
}) => {
  return (
    <Button
      onClick={onClick}
      onMouseDown={(event) => event.stopPropagation()}
      color="primary"
      size="small"
      aria-label="String Card Show/Hide Text"
    >
      {children}
    </Button>
  );
};

type StringComparisonProps = {
  englishString?: IJobString;
  dialectString?: IJobString;
  cutoff?: number;
};

const StringComparison = ({
  englishString,
  dialectString,
  cutoff = 250,
}: StringComparisonProps) => {
  const {
    displayText: englishDisplayText,
    expandedStatus: englishExpanded,
    toggleExpanded: toggleEnglishExpanded,
  } = useShowMoreText(englishString?.value || '', cutoff);
  const {
    displayText: dialectDisplayText,
    expandedStatus: dialectExpanded,
    toggleExpanded: toggleDialectExpanded,
  } = useShowMoreText(dialectString?.value || '', cutoff);

  const expandedStatus = combineExpandedStatuses(
    englishExpanded,
    dialectExpanded,
  );

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleEnglishExpanded();
    toggleDialectExpanded();
  };
  return (
    <Grid container>
      <Grid
        item
        xs={6}
        sx={(theme) => ({
          paddingRight: theme.spacing(2),
          borderRight: `1px solid ${theme.palette.grey[400]}`,
        })}
      >
        <P variant="body-bold">String</P>
        <StyledP>{englishDisplayText}</StyledP>
      </Grid>
      <Grid item xs={6} sx={(theme) => ({ paddingLeft: theme.spacing(2) })}>
        <P variant="body-bold">Target dialect</P>
        <StyledP>{dialectDisplayText}</StyledP>
      </Grid>
      {expandedStatus === 'COLLAPSED' && (
        <Grid item sx={{ marginLeft: 'auto' }}>
          <ShowHideButton onClick={handleToggle}>Read more</ShowHideButton>
        </Grid>
      )}
      {expandedStatus === 'EXPANDED' && (
        <Grid item sx={{ marginLeft: 'auto' }}>
          <ShowHideButton onClick={handleToggle}>Close text</ShowHideButton>
        </Grid>
      )}
    </Grid>
  );
};

type Dialects = {
  [key in keyof Omit<IDialects, typeof ENGLISH_DIALECT>]?: IJobString;
} & {
  [ENGLISH_DIALECT]: IJobString;
};

type IGrammarContextJobStrings = {
  [grammarContext in GrammarContext]?: Dialects;
};

type StringCardProps = {
  strings: IGrammarContextJobStrings;
  selected: boolean;
  onSelect: () => void;
  dialect: keyof IDialects;
  jobKey: string;
  jobId?: string;
  selectable: boolean;
  showAlreadyInThisJob: boolean;
  inCurrentJob: boolean;
};

const StringCard = ({
  strings,
  selected,
  onSelect,
  dialect,
  jobKey,
  jobId,
  selectable,
  showAlreadyInThisJob,
  inCurrentJob,
}: StringCardProps) => {
  const stringsToDisplay = strings.NaN || strings.FaF;
  const englishString = stringsToDisplay && stringsToDisplay[ENGLISH_DIALECT];
  const dialectString = stringsToDisplay && stringsToDisplay[dialect];

  const [variantsOpen, setVariantsOpen] = React.useState<boolean>(false);
  const {
    truncated: truncatedJobKey,
    setOuterContainerRef: setOuterJobKeyContainerRef,
  } = useMiddleTruncateText(jobKey, 'Category: ');

  const translated = GRAMMAR_CONTEXTS.some(
    (grammarContext) => strings[grammarContext]?.[dialect],
  );

  const otherJobId = GRAMMAR_CONTEXTS.reduce<string | undefined>(
    (acc, grammarContext) => {
      if (acc) {
        return acc;
      }
      return strings?.[grammarContext]?.[dialect]?.activeJobId;
    },
    undefined,
  );

  const inAnotherJob = !!otherJobId && otherJobId !== jobId;

  const handleSelect = () => {
    if (!inAnotherJob && selectable) {
      onSelect();
    }
  };

  return (
    <Card
      tabIndex={0}
      variant="outlined"
      onMouseDown={handleSelect}
      sx={(theme) => ({
        borderColor: theme.palette.grey[500],
        cursor: selectable ? 'pointer' : undefined,
      })}
      elevation={0}
      data-testid="string-list-card"
    >
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            {selectable && (
              <Checkbox
                aria-label={
                  selected
                    ? `Deselect string ${jobKey}`
                    : `Select string ${jobKey}`
                }
                role="checkbox"
                disabled={inAnotherJob}
                checked={selected || inAnotherJob}
                inputProps={{ 'aria-label': `Checkbox to select ${jobKey}` }}
                color="primary"
                sx={{ padding: '0' }}
              />
            )}
          </Grid>
          {translated && (
            <Grid item sx={{ marginLeft: 'auto' }}>
              <StyledTag
                label="&nbsp;Translated"
                icon={<CheckIcon />}
                color="darkGreen"
              />
            </Grid>
          )}
          {showAlreadyInThisJob && inCurrentJob && (
            <Grid item>
              <StyledSpan>
                This string is already part of current job
              </StyledSpan>
            </Grid>
          )}
          {inAnotherJob && (
            <Grid item>
              <StyledSpan>
                This string is already part of job:{' '}
                <Link
                  component={RouterLink}
                  to={`/jobs/${otherJobId}`}
                  sx={{ fontWeight: localTheme.typography.boldWeight }}
                >
                  {otherJobId}
                </Link>
              </StyledSpan>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardContent sx={{ paddingTop: '0' }}>
        <StringComparison
          englishString={englishString}
          dialectString={dialectString}
        />
      </CardContent>
      <CardContent sx={{ '&:last-child': { padding: '0' } }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={(theme) => ({
            paddingRight: theme.spacing(2),
            backgroundColor: theme.palette.grey[400],
          })}
        >
          <Grid item>
            <Button
              onClick={() => setVariantsOpen(!variantsOpen)}
              onMouseDown={(event) => event.stopPropagation()}
              color="primary"
            >
              {variantsOpen ? 'Close variants' : 'Show variants'}
            </Button>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            wrap="nowrap"
            xs={6}
            ref={(ro) => setOuterJobKeyContainerRef(ro!)}
          >
            <Span variant="body-bold">Category:&nbsp;</Span>
            <Tooltip title={jobKey}>
              <Typography aria-label={`Job key label ${jobKey}`}>
                {truncatedJobKey}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
        <Collapse in={variantsOpen} unmountOnExit>
          {GRAMMAR_CONTEXTS.map((grammarContext) => {
            const contextStrings = strings[grammarContext];
            if (!contextStrings) {
              return null;
            }
            const engString = contextStrings[ENGLISH_DIALECT];
            const diString = contextStrings[dialect];
            return (
              <CardContent
                key={grammarContext}
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.palette.info.dark}`,
                  '&:last-child': { borderBottom: '0' },
                  '&:nth-child(2n)': {
                    backgroundColor: theme.palette.action.disabled,
                  },
                })}
              >
                <Grid
                  container
                  spacing={2}
                  sx={(theme) => ({ paddingTop: theme.spacing(1) })}
                >
                  <Grid item xs={12}>
                    <StyledGrammarContext>
                      {grammarContext}
                    </StyledGrammarContext>
                  </Grid>
                  <Grid item xs={12}>
                    <StringComparison
                      englishString={engString}
                      dialectString={diString}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            );
          })}
        </Collapse>
      </CardContent>
    </Card>
  );
};

export type StringListProps = {
  jobId?: string;
  dialect: keyof IDialects;
  translations: JobTranslation[];
  strings: [string, IJobString[]][];
  selectedStrings: string[];
  setSelectedStrings: (selected: string[]) => void;
  selectable?: boolean;
  showAlreadyInThisJob?: boolean;
};

const StringList = ({
  jobId,
  dialect,
  translations,
  strings,
  selectedStrings,
  setSelectedStrings,
  selectable = false,
  showAlreadyInThisJob = false,
}: StringListProps) => {
  if (strings?.length < 1 || strings === undefined) return <EmptyList />;

  const list = strings.map(([key, jobStrings]) => {
    const stringsByGrammarContext: IGrammarContextJobStrings =
      jobStrings.reduce(
        (acc, jobString) => ({
          ...acc,
          [jobString.grammarContext]: {
            ...acc[jobString.grammarContext],
            [jobString.dialect]: jobString,
          },
        }),
        Object.create(null),
      );
    const inCurrentJob = translations.some(
      (translation) => translation.key === key,
    );

    return (
      <Grid item key={key} xs={12} data-testid="string-list">
        <StringCard
          dialect={dialect}
          jobId={jobId}
          jobKey={key}
          strings={stringsByGrammarContext}
          selectable={selectable}
          selected={selectedStrings.includes(key)}
          inCurrentJob={inCurrentJob}
          showAlreadyInThisJob={showAlreadyInThisJob}
          onSelect={() =>
            setSelectedStrings(toggleSelected(selectedStrings, key))
          }
        />
      </Grid>
    );
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{list}</>;
};

export default StringList;
