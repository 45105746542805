import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import { ChangeEvent } from 'react';
import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { H4, Text } from '@insights-ltd/design-library';
import { getAvailableStatusTransitions, JobStatus } from 'variables';
import { useEditJob } from 'api/hooks';
import { JobButton } from 'components/Jobs';
import { IJob } from 'types/job';
import { JobsStatus } from 'components/Jobs/Table/JobsStatus';
import { useJobContext } from 'pages/Job/hooks/jobContext';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

type Props = {
  job: IJob;
};

const JobStatusDialog = ({ job }: Props) => {
  const {
    jobStatusDialog: { open, closeDialog: onClose },
  } = useJobContext();
  const {
    mutate: editJob,
    status: editJobStatus,
    reset: resetEditJob,
  } = useEditJob();

  const onSubmit = (jobStatus: JobStatus) => {
    const { deadlineDate, description, id, owner, translator } = job;
    return editJob({
      deadlineDate,
      description,
      id,
      owner,
      translator,
      status: jobStatus,
    });
  };
  const [status, setStatus] = React.useState<JobStatus | undefined>(undefined);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as JobStatus);
  };

  React.useEffect(() => {
    setStatus(undefined);
  }, [open]);
  const handleSubmit = async () => {
    onClose();
    return onSubmit(status!);
  };
  const availableStatusTransitions = getAvailableStatusTransitions(job.status);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <H4>Change job status</H4>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose} size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={{
            maxWidth: '360px',
          }}
          dividers
        >
          <DialogContentText>
            Changing the job status will update the workflow and provide
            relevant actions to progress the job.
          </DialogContentText>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="status"
              name="status"
              value={job.status}
              onChange={handleChange}
            >
              {availableStatusTransitions.map((jobStatus) => (
                <FormControlLabel
                  key={jobStatus.id}
                  value={jobStatus.value}
                  control={<Radio checked={jobStatus.value === status} />}
                  label={<JobsStatus jobStatus={jobStatus.value} />}
                />
              ))}
            </RadioGroup>
            {status === 'CANCELLED' && (
              <Alert
                severity="error"
                icon={false}
                sx={(theme) => ({
                  border: 0,
                  marginTop: theme.spacing(spacingSizeMap.XS),
                })}
              >
                <Text color="error" variant="body-bold">
                  Changing the status to &quot;cancelled&quot; will prevent this
                  job from being progressed any further. This change cannot be
                  undone.
                </Text>
              </Alert>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <JobButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                disabled={!status}
              >
                Change status
              </JobButton>
            </Grid>
            <Grid item xs={12}>
              <JobButton
                fullWidth
                onClick={onClose}
                variant="outlined"
                color="primary"
              >
                Cancel
              </JobButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={editJobStatus === 'success'}
        autoHideDuration={6000}
        onClose={resetEditJob}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert elevation={6} onClose={resetEditJob} severity="success">
          Job status updated
        </Alert>
      </Snackbar>
    </>
  );
};

export default JobStatusDialog;
