import React, { ReactNode } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { CreateJobType } from 'types/job';
import {
  DATE_GLOBAL_FORMAT,
  Dialect,
  languages,
  languagesWithoutEnglish,
} from 'variables';
import { DatePickerV2 } from '@insights-ltd/design-library';
import { DateTime } from 'luxon';
import { OwnersSelect, TranslatorsSelect, DialectSelect } from './Selects';

const ARBITRARY_MAX_LENGTH = 100;

interface Props {
  edit?: boolean;
  onSubmit: (job: CreateJobType) => void;
  defaultValues?: CreateJobType;
  children: ReactNode;
}

const JobForm = ({
  edit = false,
  onSubmit,
  defaultValues,
  children,
}: Props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<CreateJobType>({
    defaultValues,
  });

  const disabledIfEditing = edit;

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        id="title"
        label="Job Title"
        required
        variant="outlined"
        fullWidth
        inputProps={{ maxLength: ARBITRARY_MAX_LENGTH }}
        error={Boolean(errors.title)}
        helperText={errors.title && 'Required'}
        disabled={disabledIfEditing}
        {...register('title', { required: true })}
      />
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        sx={(theme) => ({ marginTop: theme.spacing(3) })}
      >
        <Grid item md={6} xs={12}>
          <FormControl
            error={Boolean(errors.dialect)}
            variant="outlined"
            required
            fullWidth
            disabled={disabledIfEditing}
          >
            <InputLabel id="language-label">Language</InputLabel>
            <Controller
              render={({ field }) => (
                <DialectSelect
                  id="language"
                  labelId="language-label"
                  label="Language"
                  options={edit ? languages : languagesWithoutEnglish}
                  {...field}
                />
              )}
              name="dialect"
              rules={{ required: true }}
              control={control}
              defaultValue={'' as Dialect}
            />
            {errors.dialect && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id="requester"
            label="Requester"
            required
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: ARBITRARY_MAX_LENGTH }}
            error={Boolean(errors.requester)}
            helperText={errors.requester && 'Required'}
            disabled={disabledIfEditing}
            {...register('requester', { required: true })}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        sx={(theme) => ({ marginTop: theme.spacing(3) })}
      >
        <Grid item md={6} xs={12}>
          <FormControl
            error={Boolean(errors.translator)}
            variant="outlined"
            fullWidth
            required
          >
            <InputLabel id="translator-label">Translator</InputLabel>
            <Controller
              render={({ field }) => (
                <TranslatorsSelect
                  error={!!errors.translator}
                  id="translator"
                  labelId="translator-label"
                  label="Translator"
                  {...field}
                />
              )}
              name="translator"
              control={control}
              rules={{ required: true }}
              defaultValue=""
            />
            {errors.translator && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl
            required
            variant="outlined"
            fullWidth
            error={Boolean(errors.owner)}
          >
            <InputLabel id="owner-label">Owner</InputLabel>
            <Controller
              render={({ field }) => (
                <OwnersSelect
                  id="owner"
                  labelId="owner-label"
                  label="Owner"
                  {...field}
                />
              )}
              name="owner"
              rules={{ required: true }}
              control={control}
              defaultValue=""
            />
            {errors.owner && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        sx={(theme) => ({ marginTop: theme.spacing(3) })}
      >
        <Grid item md={6} xs={12}>
          <Controller
            control={control}
            defaultValue={null}
            name="deadlineDate"
            render={({ field: { onChange, value } }) => (
              <DatePickerV2
                disablePast
                format={DATE_GLOBAL_FORMAT}
                labelText="Deadline"
                onChange={(date) => {
                  onChange(date?.toISO());
                }}
                sx={{
                  marginBottom: '0',
                  marginTop: '0',
                }}
                value={value ? DateTime.fromISO(value) : null}
              />
            )}
          />
        </Grid>
      </Grid>
      <TextField
        id="description"
        label="Description"
        variant="outlined"
        multiline
        rows={5}
        fullWidth
        sx={(theme) => ({ marginTop: theme.spacing(3) })}
        {...register('description')}
      />
      {children}
    </form>
  );
};

export default JobForm;
